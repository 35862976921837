import './App.css';
import { Parallax } from 'react-parallax';
import cmdLogo from './res/cmd.png';
import queueLogo from './res/queue.png';
import kopyUI from './res/kopy-ui.png';
import thinkingPerson from './res/thinking-person.png';
import kopyLogo from './res/logo.png';

function App() {

  function downloadApp() {
    console.log("Download now");

  }
  
  return (
    <div className="App">
      <Parallax blur={{ min: -15, max: 15 }} strength={-200}>
        <div className="container">
          Kopy is a way to rethink copy and paste.

          <img style={{marginTop: 20}} className='cmd-image' src={thinkingPerson} width={100} height={100}/> 

          <div style={{marginTop: 40, fontSize: 20}}>
            Have you ever needed to copy multiple items without overriding the clipboard?
          </div>

        </div>

        <div className="down-arrow"></div>
      </Parallax>

      <Parallax blur={{ min: -15, max: 15 }} strength={-200}>
        <div className="container">
          <img src={queueLogo} height={80} width={80} />
            <div>
              <span>Kopy creates a queue of textual data and keeps it in memory for future use</span>
            </div>
           
            <hr style={{width: 100}}></hr>

            <div style={{padding: 10, fontSize: 20}}>
              <img className='cmd-image' src={cmdLogo} width={40} height={40}/> 
              + K will copy the clipboard
            </div>
            <div style={{padding: 10, fontSize: 20}}>
              <img className='cmd-image' src={cmdLogo} width={40} height={40}/> 
              + L will overwrite the clipboard with selected text <span style={{fontSize: 12}}>(and removes it)</span>
            </div>
            <div style={{padding: 10, fontSize: 20}}>
              <img className='cmd-image' src={cmdLogo} width={40} height={40}/> 
              + I will move the selector up
            </div>
            <div style={{padding: 10, fontSize: 20}}>
              <img className='cmd-image' src={cmdLogo} width={40} height={40}/> 
              + O will move the selector down
            </div>
        </div>
        <div className="down-arrow"></div>
      </Parallax>

      <Parallax blur={{ min: -15, max: 15 }} strength={-200}>
        <div className="container">
          <img src={kopyLogo} height={80} width={80} />
            <div>
              <span>Kopy is <b>non intrusive</b>. It does not bind to the default COPY or PASTE (ctrl + c / ctrl + v)</span>
            </div>
           
            <hr style={{width: 100}}></hr>

            <div style={{padding: 10, fontSize: 20}}>
              It runs in the background and provides a simple interface to display the queue
            </div>
            <div style={{padding: 10, fontSize: 15}}>
              <img className='image-app' src={kopyUI} width={200} height={200}/> 
                <div style={{padding: 20}}>
                  Kopy lives in your taskbar.
                </div>

                
            </div>
        </div>
        <div className="down-arrow"></div>
      </Parallax>

        <Parallax blur={{ min: -15, max: 15 }} strength={-200}>
            <div className="container">
              <div style={{padding: 30}}>
                Kopy is <b>currently</b> available for OSX. Download the Beta <b>now</b>
              </div>

              <a href="https://getkopyapp.s3.amazonaws.com/kopy.zip" download className="download-button" onClick={downloadApp}>
                <div className="docs"><svg className="css-i6dzq1" strokeLinejoin="round" strokeLinecap="round" fill="none" strokeWidth="2" stroke="currentColor" height="20" width="20" viewBox="0 0 24 24"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line y2="13" x2="8" y1="13" x1="16"></line><line y2="17" x2="8" y1="17" x1="16"></line><polyline points="10 9 9 9 8 9"></polyline></svg> Download Kopy (Beta) 0.1.0</div>
                <div className="download">
                  <svg className="css-i6dzq1" strokeLinejoin="round" strokeLinecap="round" fill="none" strokeWidth="2" stroke="currentColor" height="24" width="24" viewBox="0 0 24 24"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line y2="3" x2="12" y1="15" x1="12"></line></svg>
                </div>
              </a>
              <div style={{fontSize: 14, padding: 30}}>
                *Make sure to right click {'>'} open
              </div>
            </div>
        </Parallax>

        <Parallax blur={{ min: -15, max: 15 }} strength={-200}>
        <div className="container">
          <img src={kopyLogo} height={80} width={80} />
            <div>
              <span>Q&A</span>
            </div>
           
            <hr style={{width: 100}}></hr>

            <div style={{padding: 10, fontSize: 20}}>
              <b>Why don't you just overrride the COPY and PASTE functions?</b>
              <div>
                While at a glance this might seem like the most obvious appraoch, it becomes <b>intrusive.</b> I am not requiring keyboard access and never will.
              </div>
            </div>

            <div style={{padding: 10, fontSize: 20}}>
              <b>Will this record my keyboard strokes?</b>
              <div>
                No, I just bind a few keys and release them when the application is closed. Kopy is non intrusive
              </div>
            </div>
        </div>
      </Parallax>
    </div>
  );
}

export default App;
